import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../../css/Education.css";
import Trending from "../Trending";
import Editors from "../Editors";
import Latest from "../Latest";
import "../../css/navbar.css";
import "../../css/Global.css";
import "../../css/Home.css";
import axios from "axios";
import Side_Widget from "../Side_Widget";
import Footer from "../Footer";
import MostViewed from "../MostViewed";


const Gaming = (props) => {
  const [categoryData, setcategoryData] = useState([])
  const [state, sampleState] = useState("")

  return (
    <div>
      <Header />
      <div className="education_banner_div">
        <div className="education_banner_content">
          <p>Browsing Category</p>
          <h3>Gaming</h3>
        </div>
      </div>

      <div className="home_section">
        <div className="container-fluid containerBox">
          <div className="row">
            <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
              <div>
                <MostViewed />
                {/* <Trending />
                  <Editors /> */}
                <Latest url={props.location.data.url} />
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
              <Side_Widget />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gaming;
