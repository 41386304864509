import { React, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../css/Footer.css";
import FLogo from "../images/FLogo.png";
import * as emailjs from "emailjs-com";
import {
  Button,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { render } from "@testing-library/react";
const Footer = () => {
  const [name, setname] = useState();
  const [email, setemail] = useState();

  const [subject, setsubject] = useState();

  // const [message, setmessage] = useState();
  const [success, setsuccess] = useState();
  const [error, seterror] = useState();
  const [error1, seterror1] = useState();


  const [year, setyear] = useState();

  const handleChange = (e) => {
    // if (param == "name") {
    //   setname(e.target.value);
    // }

    setemail(e.target.value);





    // this.setState({ [param]: e.target.value })
  };
  // useEffect(()=>{
  //   window.scrollTo(0, 0);
  // })

  useEffect(() => {
    var d = new Date();
    var n = d.getFullYear();
    setyear(n);
  }, []);

  const handleSubmit = (e) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let templateParams = {
      from_name: "info@neodrafts.com",
      to_name: email,
      // subject: subject,
      // message_html: message,
    };
    e.preventDefault();
    if (!email) {
      seterror("*Enter Email")
    }
    else if (!re.test(email)) {
      seterror("*Enter Valid Email")
    }
    else {
      seterror("")
      setemail("")
      emailjs
        .send(
          "service_unp5c0o",
          "template_jaapmqa",
          templateParams,
          "user_taUqAycLTKe4eRKwWaV4Y"
        )
        .then(
          function (response) {
            if (response.text == "OK") {
              setsuccess(
                "Thanks! We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Please come back and see us often."
              );
            }
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }

  };

  return (
    <div className="container-fluid ne0_footer">
      <div className="ne0_footer_top d-flex justify-content-center align-items-center">
        <div className="containerBox">
          <Row className="w-100">
            <Col xs={12} md={6} lg={3} xl={3} className="d-flex b_right">
              <div className="footer_logo_div d-flex align-items-center">
                <img src={FLogo} />
              </div>
            </Col>
            <Col xs={12} md={6} lg={2} xl={2} className="b_right p_left">
              <div className="footer_info">
                <p className="font-weight-bold footer_head">Information</p>
                <Link to="/aboutus">
                  <p>About Us</p>
                </Link>
                <Link to="/contact">
                  <p>Contact Us</p>
                </Link>
                <Link to="/terms-of-use">
                  <p>Terms of use</p>
                </Link>
                <Link to="/privacy">
                  <p>Privacy Policy</p>
                </Link>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} xl={3} className="b_right p_left">
              <Row>
                <Col xs={6} md={6} lg={6} xl={6} className="">
                  <div className="footer_info">
                    <p className="font-weight-bold footer_head">Categories</p>
                    {/* "/category/" + "gaming" */}
                    <Link to={{ pathname: "/category/" + "education" }}>
                      <p>Education</p>
                    </Link>
                    {/* <Link to={{ pathname: "/category/" + "dating" }}>
                      <p>Dating</p>
                    </Link> */}
                    <Link to={{ pathname: "/category/" + "gaming" }}>
                      <p>Gaming</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "health" }}>
                      <p>Health</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "survey" }}>
                      <p>Survey</p>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} md={6} lg={6} xl={6} className="">
                  <div className="footer_info">
                    <p className="font-weight-bold footer_head invisible">
                      Categories
                    </p>

                    <Link to={{ pathname: "/category/" + "finance" }}>
                      <p>Finance</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "wellness" }}>
                      <p>Wellness</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "Insurance" }}>
                      <p>Insurance</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "news" }}>
                      <p>News</p>
                    </Link>
                    <Link to={{ pathname: "/category/" + "technology" }}>
                      <p>Technology</p>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} lg={4} xl={4} className="p_left">
              <div>
                <p className="font-weight-bold footer_head">NEWSLETTER</p>

                <Form
                  className="form_email position-relative"
                  onSubmit={handleSubmit}
                >
                  <Input
                    type="text"
                    className="footer_email_input text-primary"
                    name="email"
                    value={email}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter email"
                  />

                  <Button className="form_button" type="submit">
                    Subscribe
                    {/* <i className="fa fa-sign-in" aria-hidden="true"></i> */}
                  </Button>
                  <p style={{ color: "#000", marginTop: "1rem" }}>{success}</p>
                  <p style={{ color: "red", background: "#fff", textAlign: "center", marginTop: "1rem" }}>{error}</p>
                  {/* <p style={{ color: "red" }}>{error1}</p> */}


                </Form>

                {/* <Form method ="post">


              <div className="footer_email_div">
                <input
                  type="email"
                  className="footer_email_input"
                  placeholder="Email Address"
                />
               
              </div>
              <input type="button" value="Send Email"/>

              </Form> */}

                <div className="footer_socialMedia">
                  <a target="_blank" href="https://www.facebook.com/Neodrafts1">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/company/neodrafts/">
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  <a target="_blank" href="https://twitter.com/TheNeoDrafts?s=09">
                    <i className="fa fa-twitter" aria-hidden="true"></i>

                  </a>
                  <i className="fa fa-pinterest" aria-hidden="true"></i>
                  <a target="_blank" href="https://www.instagram.com/neodrafts/?hl=en">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="ne0_footer_bottom">
        <div className="containerBox">
          <p>
            Copyright © {year}{" "}
            <span className="font-weight-bold">NeoDrafts</span>. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
