import axios from "axios";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "../css/Trending.css";
import sample10 from "../images/sample10.jpg";
import sample5 from "../images/sample5.jpg";
import sample6 from "../images/sample6.jpg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../css/Home_Banner.css";
import { TitleComponent } from "./TitleComponent";

const You_May_Like = (props) => {
  const [education_latest, seteducation_latest] = useState([]);
  const [education_latest1, seteducation_latest1] = useState([]);

  const [title_array, settitle_array] = useState([]);
  const [feautured_media, setfeautured_media] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  useEffect(async () => {
    const config = {
      url: props.url,
      method: "get",
    };
    await axios(config)
      .then(async (res) => {
        let edu_data = res.data;
        let i = 0;
        for (const item of edu_data) {
          const config1 = {
            url: "https://api.neodrafts.com/wp-json/base/views/" + item.id,
            method: "get",
          };

          const config2 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          await axios(config1).then((result) => {
            if (result.data) {
              edu_data[i++].most_view = result.data;
            } else {
              edu_data[i++].most_view = null;
            }
          });

        }

        seteducation_latest(edu_data);
      })

      .catch((error) => {
        console.log("error", error);
      });
  }, [props.url]);

  const arr_views = [];

  useEffect(async () => {
    let edu_data = education_latest.filter((r) => r.featured_media != 18907 && r.featured_media != 28329 && r.featured_media != 0);
    // edu_data = res.data.filter((r) => r.featured_media != 18907);
    let i = 0;
    for (const item of edu_data) {
      const config1 = {
        url: "https://api.neodrafts.com/wp-json/wp/v2/media/" + item.featured_media,
        method: "get",
      };

      const result = await axios(config1).then((result) => {
        if (result.data && result.data.source_url) {
          edu_data[i++].image_url = result.data.source_url;
        } else {
          edu_data[i++].image_url = null;
        }
      });
    }

    seteducation_latest1(edu_data);
  }, [education_latest]);
  const educ = education_latest1.sort((a, b) => {
    return b.most_view - a.most_view;
  });

  let education_data = educ.map((item, index) => {
    if (index < 3) {
      return (
        <>
          {
            item.title &&
            <TitleComponent yoast={item.yoast_head} />
          }

          <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
            <Link
              to={{
                pathname: `/${item.slug}`,
                state: {
                  item: item,
                  index: index,
                  you_may_like: educ
                }
              }}
            >
              <div className="trending_col">
                <div className="img_yml">
                  <img src={item.image_url} />

                </div>

                <h4>{item.title.rendered}</h4>
                <h5>
                  Read More{" "}
                  <span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </h5>
              </div>
            </Link>
          </div>
        </>
      );
    }
  });

  return (
    <div>
      <div className="trending_component">
        {/* <h2>Most Viewed Articles</h2> */}
        <p className="text-center div_title">You may also like :</p>
        {/* <div>
        {education_data}
        </div> */}
        <div className="row">{education_data}</div>
        {/* <Link to={{ pathname: "/most-viewed-articles", data: props.url }}>
          <button>
            View All{" "}
            <span>
              <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </span>
          </button>
        </Link> */}
      </div>
    </div>
  );
};

export default You_May_Like;
