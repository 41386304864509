import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { TitleComponent } from './TitleComponent';

const Terms = () => {
    return (
        <div>

            <TitleComponent/>
<Header/>
       
        <div className="terms_div containerBox">
            <h2>Terms of Use
</h2>
<p>
NeoDrafts is a fresh blogging platform that stands for varied interests. You agree to abide by these Terms of Use by accessing the Internet site & mobile applications located at <Link to="/">www.neodrafts.com (‘the Site’).</Link> Besides this, you also agree to follow any of the services offered in conjunction with the Site (‘the Service’), as they may be modified by Neodrafts Pvt Ltd. (‘the Company’) from time to time in its absolute discretion. When you click on sign-up or sign-in you agree to these terms of use and conditions.
<br/>
<br/>

The Company will post a notice on the Website when these Terms of Use have been amended or otherwise revised. It is your responsibility to review these Terms of Use periodically, and if you find these Terms of Use unacceptable at any time, you must immediately leave the Site and stop all use of the Service and the Website.
<br/><br/>
You accept that you are at least 18 years of age and legally capable of entering into this agreement in order to access this website.
<br/><br/>
Your Account and Duties
<br/><br/>
The use of the services and the content they provide, which must comply with the privacy policy and terms of use, is the responsibility of the people using the site. Unless they have the permission to do so, they can not copy or use plagiarised material or upload any content.
<br/><br/>
People who sign up for or sign up to use the services must be 18 years of age. By following the security steps, keep your account details secure and keep your account safe with a unique password. It is in your interest not to share with anyone your password. If you share your account information with others, NeoDrafts is not responsible for the misuse of your account information.
<br/><br/>
You assume full responsibility for your agreement and state that you are allowed to do so upon agreeing to the terms and conditions for using these services.<br/><br/>
</p>
        </div>
        <Footer/>
        </div>
    );
};

export default Terms;