
import editor1 from '../images/editor1.jpeg';
import editor2 from '../images/editor2.jpeg';
import editor3 from '../images/editor3.jpeg';
import editor4 from '../images/editor4.jpeg';
import editor5 from '../images/editor5.jpeg';
// import editor6 from '../images/editor6.jpeg';
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "../css/Trending.css";
import "../css/Editors.css";
import sample10 from "../images/sample10.jpg";
import sample5 from "../images/sample5.jpg";
import sample6 from "../images/sample6.jpg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Editors_category = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div>
      <Header />
      <div className="education_banner_div editors_banner_image_div">

        <div className="education_banner_content">
          <h3>Editor's Pick</h3>
        </div>
      </div>

      <div className="containerBox">
        <div className="trending_component">
          <div className="row">

            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"earn-money-through-surveys-in-an-instant"}`,





                }}
              >
                <div className="trending_col">
                  <img src={editor1} />

                  <h4>Earn Money Through Surveys In An Instant
                  </h4>
                  <h5>
                    Read More{" "}
                    <span>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </h5>
                </div>
              </Link>
            </div>



            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"join-the-highest-paying-survey-platforms-and-earn"}`,

                }}>

                <div className="trending_col">
                  <img src={editor3} alt="Image" />

                  <h4>Learn A-Z About unclaimed assets with a free guide
                  </h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>

            </div>
            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">

              <Link
                to={{
                  pathname: `/${"find-your-unclaimed-assets-with-a-free-guide"}`,

                }}>

                <div className="trending_col">
                  <img src={editor2} alt="Image" />

                  <h4>    Join the Highest Paying Survey Platforms and Earn

                  </h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>

            </div>

          </div>

          <div className="row">

            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"get-free-dunkin-donuts-gift-card-worth-it-500"}`,





                }}
              >
                <div className="trending_col">
                  <img src={editor4} />

                  <h4>Earn Gift Cards By Taking Surveys At This Site

                  </h4>
                  <h5>
                    Read More{" "}
                    <span>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </h5>
                </div>
              </Link>
            </div>



            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"multivitamin-is-best-for-erectile-dysfunction"}`,

                }}>

                <div className="trending_col">
                  <img src={editor5} alt="Image" />

                  <h4>Which multivitamin is best for Erectile Dysfunction?

                  </h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>

            </div>
            {/* <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">

<Link
     to={{
       pathname: `/${"find-your-unclaimed-assets-with-a-free-guide"}` ,
       
       }}>

<div className="trending_col">
<img src={editor7} alt="Image"/>

<h4>    Join the Highest Paying Survey Platforms and Earn

</h4>
<h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
</span></h5>
</div>
</Link>

</div> */}

          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Editors_category;