import { React, useEffect, useState } from "react";
// import { Form } from 'react-bootstrap';
import "../css/Contact.css";
import Footer from "./Footer";
import * as emailjs from "emailjs-com";

import Header from "./Header";
import { TitleComponent } from "./TitleComponent";
import {
  Button,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
const Contact = () => {
  const [name, setname] = useState();
  const [email, setemail] = useState();

  const [subject, setsubject] = useState();
  const [err, seterr] = useState();


  const [message, setmessage] = useState();

  const [success, setsuccess] = useState();

  const [email_err, setemail_err] = useState();
  const [name_err, setname_err] = useState();

  const [message_err, setmessage_err] = useState();
  const [state1, setstate1] = useState(false);
  const [state2, setstate2] = useState(false);

  const handleChange = (e, param) => {
    if (param == "name") {
      setname(e.target.value);
    }
    if (param == "email") {
      setemail(e.target.value);
    }
    if (param == "message") {
      setmessage(e.target.value);
    }

    // this.setState({ [param]: e.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //   const  email = email
    //   const  name = name

    //   const  message = message
    let templateParams = {
      from_name: name,

      message: message,
      reply_to: email,
    };
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email || !name || !message) {
      seterr("*Enter All Feilds")
    }
    else {
      seterr("")


      if (name.length < 3) {
        setname_err("Name should contain minimum 3 charecters")
      }
      else if (!re.test(email)) {
        setemail_err("Enter Valid Email")
        setname_err("")

      }
      else {
        setemail_err("")

        emailjs
          .send(
            "service_unp5c0o",
            "template_47qofvz",
            templateParams,
            "user_taUqAycLTKe4eRKwWaV4Y"
          )
          .then(
            function (response) {
              if (response.text == "OK") {
                setsuccess(
                  "Thanks! We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Please come back and see us often."
                );
                setname("");
                setemail("");
                setmessage("");
                window.setTimeout(() => window.location.reload(false), 1000);
              }

            },
            function (error) {
              console.log("FAILED...", error);
            }
          );

      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div>
      <TitleComponent title="Contact Us for fantastic source of articles - NeoDrafts" description="Best Blogging Website with Top categories like Education, Games, Health, Finance, Insurance, Dating, Entertainment, News, & Technology Contact" />

      <Header />
      <div className="contact_form_section">
        <div className="contactus_page">
          <div className="contact_form_div">
            <div className="containerBox">
              <div className="contact_form">
                <p className="contact_form-sub-heading">Want to talk?</p>
                <h2 className="contact_form_hm-heading">GET IN TOUCH</h2>
                <div className="form_div">
                  <p style={{ color: "red" }}>{err}</p>
                  <Form method="post" onSubmit={handleSubmit}>
                    <Input
                      type="text"
                      className="input_box contact"
                      name="fname"
                      value={name}
                      onChange={(e) => handleChange(e, "name")}
                      placeholder="Enter Name"
                    />
                    <p
                      style={{
                        color: "red",
                        background: "#fff",
                        height: "2.2rem",
                        margin: "0px",
                      }}
                    >
                      {name_err}
                    </p>
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => handleChange(e, "email")}
                      placeholder="Enter email"
                      className="input_box contact"
                    />
                    <p
                      style={{
                        color: "red",
                        background: "#fff",
                        height: "2.2rem",
                        margin: "0px",
                      }}
                    >
                      {email_err}
                    </p>
                    <textarea
                      rows="6"
                      type="email"
                      name="email"
                      value={message}
                      onChange={(e) => handleChange(e, "message")}
                      className="input_box contact"
                      placeholder="Message"
                    />
                    <p
                      style={{
                        color: "red",
                        background: "#fff",
                        height: "2.2rem",
                        margin: "0px",
                      }}
                    >
                      {message_err}
                    </p>
                    <Button
                      className="form_button"
                      variant="primary"
                      type="submit"
                    >
                      send
                      {/* <i className="fa fa-envelope-o" aria-hidden="true"></i> */}
                    </Button>{" "}
                  </Form>
                  <p style={{ color: "green" }}>{success}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="support_div">
            <div className="envilope_icon mb-5 text-center">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <div className="email_link mb-5 text-center">
              <a href="">support@neodrafts.com</a>
            </div>
            <div className="sm_icons text-center">

              <a target="_blank" href="https://www.facebook.com/Neodrafts1">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>





              <a target="_blank" href="https://www.linkedin.com/company/neodrafts/">
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </a>
              <a target="_blank" href="https://www.instagram.com/neodrafts/?hl=en">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>




            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
