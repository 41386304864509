import React, { useEffect, useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import Helmet from 'react-helmet';
const TitleComponent = (props) => {
  const [data1, setdata1] = useState([]);
  const [det_des, setdet_des] = useState("");
  const [det_title, setdet_title] = useState("")
  var defaultTitle = 'Neodrafts - Be Inspired. Be Informed';
  var defaultDescription = "NeoDrafts is one of the top blogging platforms that stands for varied interests and covers various niches through insightful blogs."
  useEffect(() => {
    let stringhtml = props.yoast
    var el = document.createElement('head');
    el.innerHTML = props.yoast;
    let nodArray = el.querySelectorAll('meta')
    Object.entries(nodArray).map(([key, v]) => {
      data1.push(v.content)
    })

  }, [props])

  return (

    <Helmet>

      <meta charSet="utf-8" />
      <link rel="canonical" href="https://neodrafts.com/" />

      {props.title && <title>{props.title}</title>}
      {props.yoast && <title>{data1[4]}</title>}

      {!props.title && !props.yoast && <title>{defaultTitle}</title>}

      {props.description && <meta name="description" content={props.description} />}
      {props.yoast && <meta name="description" content={data1[0]} />}
      {!props.description && !props.yoast && <meta name="description" content={defaultDescription} />}

      <meta name="keywords" content="HTML,CSS,JavaScript" />

    </Helmet>
  );
};

export { TitleComponent };

