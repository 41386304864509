import React, { Component } from 'react';
import '../css/Home_Banner.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Home_Banner extends Component {

  componentDidMount() {

    window.scrollTo(0, 0);

  }
  render() {
    return (
      <div>


        <div className="banner_div">
          <div className="banner_opacity">
            <div className="banner_heading">
              <h4>Featured<span><i className="fa fa-star-o" aria-hidden="true"></i>
              </span></h4>
            </div>
            <Link
              to={{
                pathname: `/${"how-to-plan-for-a-higher-education-through-college-navigator"}`,

              }}>
              <div className="banner_content">

                <p>February 12, 2021 Article by Annie</p>
                <h2>How To Plan For a Higher Education Through College Navigator?
                </h2>
                <button>Read More <span><i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                </span></button>
              </div>
            </Link>
          </div>



        </div>

      </div>
    );
  }
}

export default Home_Banner;