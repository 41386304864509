import React from 'react';
import Footer from './Footer';
import Header from './Header';
import "../css/Aboutus.css";
import "../css/Global.css";
import { TitleComponent } from "./TitleComponent";

const Privacy = () => {
  return (
    <div>
      <TitleComponent title="Neodrafts is a Fresh Blogging Platform - About Us" />
      <Header />
      <div>
        <div className="aboutus_page">
          <p className="aboutus-sub-heading">Learn more</p>
          <h2 className="aboutus_hm-heading privacy">Privacy</h2>

          <div className="containerBox">
            <div className="aboutus_content">
              Who We Are    <h3>
                Welcome to NeoDrafts.

              </h3>
              <br />


              NeoDrafts (“us”, “we”, or “our”) operates www.neodrafts.com.
              <br />


              This Privacy Policy regulates your visit to www.neodrafts.com, and defines how we collect, protect and disclose data that results from your use of our Service. NeoDrafts is an upcoming and fresh blogging platform.
              <br />
              This privacy policy outlines what data we collect when you use the websites, utilities, mobile apps, goods and content (‘Services’) of NeoDrafts.
              <br /><br />
              2.Information Collection and Use
              For various reasons, we gather many different kinds of information to provide and expand our service to you.
              <br /><br />
              3.Types of Data Collected
              Personal Data
              We can need you to provide us with any publicly identifying details that can be used to contact or recognise you during the use of our Service (‘Personal Data’). Information that is individually recognisable can include, but is not limited to:
              <br />
              0.1. Email Address
              <br />
              0.2. First Name and Last Name
              <br />
              0.3. Number of phones
              <br />
              0.4. Email, Region, State, Province, Postal / ZIP code, Municipality
              <br />
              0.5. Cookies and Info for Usage

              In order to contact you with updates, advertisement or advertising materials and other information that may be of interest to you, we may use your personal details. Through following the unsubscribe connection, you can opt-out of receiving any or all of these emails from us.

              What personal data we collect and why we collect it
              We gather the data displayed in the message form as users leave comments on the site, as well as the visitor’s IP address and the browser’s user agent string to help spot spam. To use, validate and encourage the services, we collect the data, gauge generic details of the users who use the services.
              The media
              You should stop uploading photos with embedded location data (EXIF GPS) used if you upload images to the website.
              <br /><br />
              4. Tracking Details for Cookies
              To monitor the operation on our Service, we use cookies and similar monitoring technology and we retain some information.
              Cookies are files containing a limited amount of data that could have a unique identifier that is anonymous. Cookies are sent from a web site to your browser and saved on your computer.
              <br /><br />
              5. Use of Data
              NeoDrafts uses the data that’s collected for multiple purposes:

              0.1. to maintain our Service;
              0.2. to inform you about any changes to our services
              0.3. to let you be a part of our various Services when you choose to do so;
              0.4. to offer customer support;
              0.5.0. Collect, analyse information so that we can improve our service;
              0.6. to monitor the use of our Service;
              0.7. Detecting, preventing and addressing technical issues;
              0.8. for miscellaneous uses with your consent;
              <br /><br />
              6. Retention of Data
              We will only retain your data for as long as it is necessary. We will use your data to resolve disputes and enforce legal agreements and policies.
              <br /><br />
              7. Transfer of Data
              Until and unless adequate controls are in place, including the security of your data and other personal information, no transfer of your personal data will take place to an organisation or a country.
              <br /><br />
              8. rights you have over your data
              If you hold an account on this site or share any feedback, you can request the receipt by us of an exported file of the personal details we keep about you, including any information you have given to us. You can also request us to delete any information we have about you. It does not contain any records that we are obligated to hold for administrative, legal or security reasons.
              <br /><br />
              9. Security of Data
              The confidentiality of your data is vital to us, but note that there is no 100 percent safe way of communication over the Internet or electronic storage. Although we aim to use commercially appropriate means to safeguard your personal data, we can not guarantee full protection of your personal data.
              <br /><br />
              10. Service Providers
              Your personal data can be accessed by these third parties in order to carry out these activities on our behalf.
              <br /><br />
              11. Analytics
              Please bear in mind that NeoDrafts may keep a track on the use of our service by using third-party Service Providers.
              <br /><br />
              12. Contact Us
              If you have any questions about this Privacy Policy, please contact us by email:
              support@neodrafts.com
              <br /><br />

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Privacy;