import React, { useEffect, useState } from "react";
import Header from "../Header";
import "../../css/Education.css";

import Trending from "../Trending";
import Editors from "../Editors";
import Latest from "../Latest";
import "../../css/navbar.css";
import "../../css/Global.css";
import "../../css/Home.css";
import axios from "axios";
import Side_Widget from "../Side_Widget";
import Footer from "../Footer";
import MostViewed from "../MostViewed";
import { TitleComponent } from "../TitleComponent";
import eduMostViewed from '../../images/EducationBanner1.png';
// import datingMostViewed from '../../images/DatingBanner1.png';
import finMostViewed from '../../images/FinanceBanner1.png';
import gamingMostViewed from '../../images/GamingBanner1.png';
import healthMostViewed from '../../images/HealthBanner1.png';
import insuMostViewed from '../../images/InsuranceBanner1.png';
import surveyMostViewed from '../../images/SurveysBanner1.png';
import wellnessMostViewed from '../../images/WellnessBanner1.png';
import currencyMostViewed from '../../images/CurrencyBanner1.png';
import { useParams } from "react-router-dom";

const Category_Main = (props) => {
  const [cat_name, setCat_name] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

  })

  useEffect(() => {
    let loc = window.location.pathname;
    var res = loc.replace("/category/", "");
    setCat_name(res);
  }, [props.data]);

  const mostViewed_slug = useParams();

  let imageURL = "";

  if (mostViewed_slug.slug == "education") {
    imageURL = eduMostViewed;
  } else if (mostViewed_slug.slug == "gaming") {
    imageURL = gamingMostViewed;
  } else if (mostViewed_slug.slug == "health") {
    imageURL = healthMostViewed;
  } else if (mostViewed_slug.slug == "survey") {
    imageURL = surveyMostViewed;
  } else if (mostViewed_slug.slug == "finance") {
    imageURL = finMostViewed;
  }
  // else if (mostViewed_slug.slug == "dating") {
  //   imageURL = datingMostViewed;
  // }
  else if (mostViewed_slug.slug == "wellness") {
    imageURL = wellnessMostViewed;
  } else if (mostViewed_slug.slug == "Insurance") {
    imageURL = insuMostViewed;
  } else if (mostViewed_slug.slug == "news") {
    imageURL = currencyMostViewed;
  } else if (mostViewed_slug.slug == "technology") {
    imageURL = currencyMostViewed;
  }



  return (
    <div>
      {props.data && props.data.meta_title && (
        <TitleComponent title={props.data.meta_title} />
      )}

      <Header />

      <div className="mostViewed_banner">
        <img src={imageURL} />
        <div className="mostViewed_banner_text">
          <p>Browsing Category</p>
          <h3>{mostViewed_slug.slug}</h3>
        </div>
      </div>

      <div className="home_section">
        <div className="container-fluid containerBox">
          <div className="row">
            <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
              <div>

                {props.data && props.data.url && (
                  <MostViewed url={props.data.url} />
                )}

                {props.data && props.data.url && (
                  <Latest url={props.data.url} />
                )}
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
              <Side_Widget img1={props.data.sidebar_image.img1} img2={props.data.sidebar_image.img2}

                link1={props.data.links.link1} link2={props.data.links.link2}

              />

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Category_Main;
