import React, { Component } from "react";
import "../css/Side_Widget.css";
import surveys from "../images/surveys.jpg";
import sample13 from "../images/sample13.png";
import WinFreebies from "../images/WinFreebies.jpg"
import FindingLove from "../images/FindingLove.jpg"
import { FaConnectdevelop } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import { RiLayoutGridFill } from "react-icons/ri";
import { BsFillArchiveFill } from "react-icons/bs";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";

class Side_Widget extends Component {
  state = {
    side_img1: "",
    side_img2: ""
  }

  render() {
    return (
      <div>
        {/* <div className="connect_div">
          <p className="icon_div_one">
            <FaConnectdevelop /> Connect with Us
          </p>
          <div className="social_icons">

          <a target="_blank" href="https://www.facebook.com/Neodrafts1">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  
                  
                  <a target="_blank" href="https://twitter.com/TheNeoDrafts?s=09">
                  <i className="fa fa-twitter" aria-hidden="true"></i>

                  </a>


            <a target="_blank" href="https://www.linkedin.com/company/neodrafts/">
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>            <i className="fa fa-pinterest" aria-hidden="true"></i>
                  <a target="_blank" href="https://www.instagram.com/neodrafts/?hl=en">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>          </div>
        </div>

        <br /> */}

        <div className="connect_div">
          <p className="icon_div_one">
            <BiTime /> Our Partners
          </p>
          <div className="recent_posts_imgs">
            {this.props.img1 ?
              <a target="_blank" href={this.props.link1}>
                <img src={this.props.img1} />
              </a>

              :
              <a target="_blank" href="/category/education">
                <img src={"https://cdn.neodrafts.com/uploads/2021/04/Instant-Loan.jpg"} alt="Image" />

              </a>

            }

            <br />

            {this.props.img2 ?
              <a target="_blank" href={this.props.link2}>

                <img src={this.props.img2} />
              </a>

              :
              <a href="/category/education" target="_blank">
                <img src={"https://cdn.neodrafts.com/uploads/2021/04/Online-Courses.jpg"} alt="Image" />

              </a>
            }

            {/* {this.props.children} */}
            {/* <Carousel>
              <Carousel.Item interval={1000}>

                <img
                  className="d-block w-100"
                  src={surveys}
                  alt="First slide"
                />
                
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={sample13}
                  alt="Second slide"
                />
                
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={WinFreebies}
                  alt="Third slide"
                />
                
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={FindingLove}
                  alt="Fourth slide"
                />
                
              </Carousel.Item>
            </Carousel> */}



          </div>
        </div>

        <br />

        <div className="connect_div">
          <p className="icon_div_one">
            <RiLayoutGridFill /> Categories
          </p>
          <div className="categories_list">
            <ul>
              <Link to="/category/education">  <li>Education</li></Link>

              <Link to="/category/gaming">  <li>Gaming</li></Link>
              <Link to="/category/health">  <li>Health</li></Link>


              <Link to="/category/survey">  <li>Survey</li></Link>



              {/* <Link to="/category/dating">  <li>Dating</li></Link> */}

              <Link to="/category/finance">  <li>Finance</li></Link>

              <Link to="/category/wellness">  <li>Wellness</li></Link>

              <Link to="/category/news">  <li>News</li></Link>
              <Link to="/category/technology">  <li>Technology</li></Link>
              <Link to="/category/Insurance">  <li>Insurance</li></Link>

            </ul>
          </div>
        </div>

        <br />

        {/* <div className="connect_div">
          <p className="icon_div_one">
            <BsFillArchiveFill /> Archive
          </p>
          <div className="categories_list">
            <ul>
            <Link to={{ pathname:`/date/${"2021"}`}}>

<li>2021</li>

</Link>
            <Link to={{ pathname:`/date/${"2020"}`}}>

              <li>2020</li>

              </Link>
             
              
            </ul>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Side_Widget;
