import React, { useState, useEffect } from 'react';
import Footer from "./Footer";
import Header from "./Header";
import "../css/Global.css";
import "../css/Side_Widget.css";
import Side_Widget from "./Side_Widget";
import "../css/Category_Detailed.css";
import { TitleComponent } from "./TitleComponent";
import You_May_Like from "./You_May_Like";
import axios from "axios";
import { NavItem, Media, Image, Form, Col, Button, FormText } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { rewind } from 'react-helmet';
import surveys from "../images/surveys.jpg";
import sample13 from "../images/sample13.png";
import WinFreebies from "../images/WinFreebies.jpg"
import FindingLove from "../images/FindingLove.jpg"

const MostViewed_Detailed = (props) => {
  const [education_latest, seteducation_latest] = useState([]);

  const [detailed_arry, setdetailed_arry] = useState([])
  const [detailed_obj, setdetailed_obj] = useState({})

  const [title, settitle] = useState({})

  const [content, setcontent] = useState({})
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  useEffect(() => {
    let routeState = []
    if (props.location.state) {
      localStorage.setItem('detailed_arry', JSON.stringify(props.location.state))
      routeState = props.location.state
    } else {
      routeState = localStorage.getItem('routeState')
      if (routeState) routeState = JSON.parse(routeState)
    }

    if (routeState) {
      setdetailed_arry(routeState)
      setdetailed_obj(routeState.item)
      settitle(routeState.item.title)
      setcontent(routeState.item.content)
    }
  }, [props.location.state])


  useEffect(() => {
    seteducation_latest(detailed_arry)
  }, [detailed_arry])


  const previos_comp = (getIndex) => {
    if (education_latest) {
      let i = 0;
      let req_index = getIndex - 1
      education_latest.you_may_like.map((item, index) => {
        if (index == req_index) {
          props.history.push({
            pathname: `/trending-detailed/${item.slug}`,
            state: {
              item: item,
              you_may_like: detailed_arry.you_may_like,
              index: index
            }
          });

        }
        else {

        }
      })


    }
  }

  const next_comp = (getIndex) => {
    if (education_latest) {
      let i = 0;
      let req_index = getIndex + 1
      education_latest.you_may_like.map((item, index) => {
        if (index == req_index) {
          props.history.push({
            pathname: `/trending-detailed/${item.slug}`,
            state: {
              item: item,
              you_may_like: detailed_arry.you_may_like,
              index: index
            }
          });

        }
        else {

        }
      })


    }
  }


  return (
    <div>
      <Header />
      <div className="banner_height_div">
        {detailed_obj.image_url &&
          <img className="content_detailed_banner" src={detailed_obj.image_url} />

        }
        <div>
          <div className="tiltle_box">
            <div className="containerBox">
              {title.rendered && (
                <div className="cd_title">
                  {title.rendered}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid containerBox">
        <div className="row">
          <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
            <div >
              {content &&
                (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.rendered,
                    }}
                  />
                )}

              <div className="share_this_div mt-5 mb-5">
                <p style={{ color: "#000", fontSize: "18px", fontWeight: 600 }}>
                  Share This
                </p>
                <div className="share_media">
                  <div className="social_icons">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                    {title &&
                      title.rendered && (
                        <a
                          href={
                            "https://twitter.com/intent/tweet?text=" +
                            title.rendered +
                            "&url=" +
                            window.href
                          }
                        >
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      )}
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
            <Side_Widget>
              <img src={FindingLove} alt="Image" />
              <br />
              <img src={WinFreebies} alt="Image" />
            </Side_Widget>
          </div>
        </div>
      </div>
      <div className="containerBox you_may_like_div mt-5 mb-5">
        {detailed_arry && detailed_arry.you_may_like && (
          <You_May_Like url={detailed_arry.you_may_like} />
        )}
      </div>

      <div className="next_page_block containerBox d-flex justify-content-between">
        {detailed_arry &&

          <button className="previous_btn" onClick={() => previos_comp(detailed_arry.index)}>Pervious</button>
        }
        <Link to="/">
          <p>back to home</p>
        </Link>
        {detailed_arry &&
          <button className="next_btn" onClick={() => next_comp(detailed_arry.index)}>Next</button>

        }
      </div>
      <div className="comments_section mt-5 mb-5">
        {detailed_obj.comments &&
          detailed_obj.comments.map((item, index) => {
            return (
              <>
                <div className="comments_display_sec">
                  <Media>
                    <img src={item.author_img} className="author_avathar" />
                    <Media.Body>
                      <h5 className="font-weight-bold">{item.name}</h5>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />

                    </Media.Body>
                  </Media>
                </div>



              </>
            );
          })}

        <div className="comments_writing_sec">
          <div className="leave_a_reply">Leave a Reply</div>
          <p className="req_para">
            Your email address will not be published. Required fields
            are marked *
          </p>
          <div className="commentsForm mt-4 mb-4">
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>COMMENT</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>



              <Form.Group id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  className="check_text"
                  label="Save my name, email, and website in this browser for the next time I comment."
                />
              </Form.Group>

              <Button
                variant="primary"
                className="post_comment"
                type="submit"
              >
                Post Comment
              </Button>
            </Form>
          </div>
        </div>


      </div>

      <Footer />

    </div>
  );
};

export default MostViewed_Detailed;