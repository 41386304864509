import React, { useState, useEffect } from 'react';
import Footer from "../Footer";
import Header from "../Header";
import "../../css/Global.css";
import "../../css/Side_Widget.css";
import Side_Widget from "../Side_Widget";
import "../../css/Category_Detailed.css";
import { TitleComponent } from "../TitleComponent";
import You_May_Like from "../You_May_Like";
import axios from "axios";
import { NavItem, Media, Image, Form, Col, Button, FormText } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { rewind } from 'react-helmet';
import createHistory from 'history/createBrowserHistory'
import surveys from "../../images/surveys.jpg";
import sample13 from "../../images/sample13.png";
import author from "../../images/author.png";
import WinFreebies from "../../images/WinFreebies.jpg"
import FindingLove from "../../images/FindingLove.jpg"
import { BeatLoader } from "react-spinners"

const Category_Detailed = (props) => {
  const history = useHistory()

  const [detailed_data, setdetailed_data] = useState({})
  const [detailed_data1, setdetailed_data1] = useState({})
  const [main_data, setmain_data] = useState({})
  const [req_url, setreq_url] = useState()
  const [edu1, setedu1] = useState([])
  const [edu2, setedu2] = useState([])
  const [index_r, setIndex_r] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  useEffect(async () => {
    const config = {
      url: "https://api.neodrafts.com/wp-json/wp/v2/posts?slug=" + props.data,
      method: "get"
    }
    setLoading(true)
    await axios(config).then((res) => {
      setdetailed_data(res.data[0])
    })
  }, [props.data])

  useEffect(async () => {
    if (Object.keys(detailed_data).length > 0) {
      let detailed = detailed_data
      const config1 = {
        url:
          "https://api.neodrafts.com/wp-json/wp/v2/media/" +
          detailed.featured_media,
        method: "get",
      };

      const result = await axios(config1).then((result) => {
        if (result.data && result.data.source_url) {
          detailed.image_url = result.data.source_url;
        } else {
          detailed.image_url = null;
        }

      });
      setdetailed_data1(detailed_data)
    }
  }, [detailed_data])

  useEffect(async () => {
    let main_data = detailed_data1;
    const new_data = []
    let i = 0;
    const config1 = {
      url:
        "https://api.neodrafts.com/wp-json/wp/v2/comments",

      method: "get",
    };

    const result = await axios(config1).then((result) => {
      const comments = []
      let i = 0;
      for (const item1 of result.data) {
        if (item1.post == main_data.id) {
          const data = {
            author_img: item1.author_avatar_urls[24],
            name: item1.author_name,
            description: item1.content.rendered
          }
          comments.push(data)

        }

      }

      main_data.comments = comments
      new_data.push(main_data)

    });
    setmain_data(new_data[0])
  }, [detailed_data1])

  useEffect(async () => {
    if (main_data.categories == "51") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51")
    }
    if (main_data.categories == "54") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54")
    }
    if (main_data.categories == "6") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6")
    }
    if (main_data.categories == "565") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565")
    }
    if (main_data.categories == "50") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50")
    }
    if (main_data.categories == "7") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7")
    }
    if (main_data.categories == "2") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2")
    }
    if (main_data.categories == "3") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3")
    }
    if (main_data.categories == "6") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6")
    }
    if (main_data.categories == "52") {
      setreq_url("https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52")
    }

  }, [main_data.categories])

  useEffect(async () => {
    const config = {
      url: req_url,
      method: "get",
    };

    await axios(config)
      .then(async (res) => {
        let edu_data = res.data.filter((r) => r.featured_media != 18907 && r.featured_media != 28329 && r.featured_media != 0);
        let i = 0;
        for (const item of edu_data) {
          const config1 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          const result = await axios(config1).then((result) => {
            if (result.data && result.data.source_url) {
              edu_data[i++].image_url = result.data.source_url;
            } else {
              edu_data[i++].image_url = null;
            }
          });
        }

        setedu1(edu_data);
      })
      .catch((error) => {
        console.log("error", error);
      });

  }, [req_url])

  useEffect(async () => {

    let main_data = edu1;
    const new_data = []
    let i = 0;
    for (const item of main_data) {
      const config1 = {
        url:
          "https://api.neodrafts.com/wp-json/wp/v2/comments",

        method: "get",
      };

      const result = await axios(config1).then((result) => {
        const comments = []
        let i = 0;
        for (const item1 of result.data) {
          if (item1.post == item.id) {
            const data = {
              author_img: item1.author_avatar_urls[24],
              name: item1.author_name,
              description: item1.content.rendered
            }
            comments.push(data)

          }

        }

        item.comments = comments
        new_data.push(item)
        setLoading(false)
      });
    }
    setedu2(new_data)

  }, [edu1])

  const previos_comp = (getIndex) => {
    if (edu2) {
      let i = 0;
      let id;
      edu2.map((item, index) => {
        if (getIndex == item.id) {
          history.push(`/${edu2[index - 1].slug}`)
        }
      })
    }
  }

  const next_comp = (getIndex) => {

    if (edu2) {
      let i = 0;
      let id;
      edu2.map((item, index) => {

        if (getIndex == item.id) {
          history.push(`/${edu2[index + 1].slug}`)
        }
      })
    }
  }

  return (
    <div>
      {main_data.title && (
        <TitleComponent yoast={main_data.yoast_head} />
      )}
      <Header />

      <div>

        {loading ? <div className="loader_div"><BeatLoader className="loader_class" size={30} color="#b62f71" /></div> :

          <div className="banner_height_div">



            {


              main_data.image_url && <img className="content_detailed_banner" src={main_data.image_url} />

            }


            <div>
              <div className="tiltle_box">
                <div className="containerBox">
                  {main_data.title && (
                    <div className="cd_title">
                      {main_data.title.rendered}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }

        <div className="container-fluid containerBox">
          <div className="row">
            <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
              <div className="content-box">
                <div className="detailed_des">
                  {main_data.content &&
                    (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: main_data.content.rendered,
                        }}
                      />
                    )}

                </div>

                <div className="share_this_div mt-5 mb-5">
                  <p style={{ color: "#000", fontSize: "18px", fontWeight: 600 }}>
                    Share This
                  </p>
                  <div className="share_media">
                    <div className="social_icons">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                      {main_data.title &&
                        main_data.title.rendered && (
                          <a
                            href={
                              "https://twitter.com/intent/tweet?text=" +
                              main_data.title.rendered +
                              "&url=" +
                              window.href
                            }
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        )}
                      <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
              <Side_Widget >
                <img width="100%" src={WinFreebies} alt="Image" />
                <br />
                <img width="100%" src={FindingLove} alt="Image" />
              </Side_Widget>
            </div>
          </div>
          <div className='row'>
            <div className="media">
              <div className="media-left">
                <img src={author} className="media-object" style={{ width: "60px " }} />
              </div>
              <div className="media-body">
                <h4 className="media-heading">Ahemad</h4>
                <p>Published in:
                  <span className='website_class'>NeoDrafts</span>
                  <span className='space_class'>&#183;</span>
                  <span className='time_class'>{main_data.date}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="containerBox you_may_like_div mt-5 mb-5">
          {req_url && (
            <You_May_Like url={req_url} />
          )}
        </div>
        <div className="comments_section mt-5 mb-5">
          {main_data.comments &&
            main_data.comments.map((item, index) => {
              return (
                <>


                  <div className="comments_display_sec">
                    <Media>
                      <img src={item.author_img} className="author_avathar" />
                      <Media.Body>
                        <h5 className="font-weight-bold">{item.name}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />

                      </Media.Body>
                    </Media>
                  </div>



                </>
              );
            })}



          <div className="next_page_block mb-5 d-flex justify-content-between">
            {main_data &&

              <button className="previous_btn" onClick={() => previos_comp(main_data.id)}>Pervious</button>
            }
            <Link to="/">
              <p>back to home</p>
            </Link>
            {main_data &&
              <button className="next_btn" onClick={() => next_comp(main_data.id)}>Next</button>

            }
          </div>
          <div className="comments_writing_sec">
            <div className="leave_a_reply">Leave a Reply</div>
            <p className="req_para">
              Your email address will not be published. Required fields
              are marked *
            </p>
            <div className="commentsForm mt-4 mb-4">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>COMMENT</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>



                <Form.Group id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    className="check_text"
                    label="Save my name, email, and website in this browser for the next time I comment."
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  className="post_comment"
                  type="submit"
                >
                  Post Comment
                </Button>
              </Form>
            </div>
          </div>


        </div>




      </div>







      <Footer />

    </div>
  );
};

export default Category_Detailed;