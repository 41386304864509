import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/Aboutus.css";
import "../css/Global.css";
import AboutUs_banner from "../images/Aboutus_banner.jpg";
import { TitleComponent } from "./TitleComponent";
class Aboutus extends Component {
  componentDidMount() {

    window.scrollTo(0, 0);

  }
  render() {
    return (
      <div>

        <TitleComponent title="Neodrafts is a Fresh Blogging Platform - About Us" description="NeoDrafts is a fresh blogging platform. It was conceived for the artistic minds to bring their ideas to life and put it to words and form" />

        <Header />

        <div>
          {/* //mmm */}
          <div className="aboutus_page">
            <p className="aboutus-sub-heading">Learn more</p>
            <h2 className="aboutus_hm-heading">ABOUT US</h2>

            <div className="position-relative">
              <div className="containerBox">
                <div className="aboutus_banner_div ">
                  <div className="aboutus_banner_img">
                    <img src={AboutUs_banner} />
                  </div>
                </div>
              </div>
            </div>
            <div className="containerBox">
              <div className="aboutus_content">
                <p>
                  Any idea needs creative thinking and dynamic minds to put it
                  to test. NeoDrafts was conceived for the artistic minds to
                  bring their ideas to life and put it to words and form.
                  NeoDrafts is a fresh blogging platform that stands for varied
                  interests. At NeoDrafts, we take bold approaches & marketing
                  strategies to connect you with great offers, deals and bonus
                  points on the Internet.
                </p>
                <p>
                  It is a fantastic source of articles, blogs and trending news
                  and opinions on a multitude of sectors, industry and topics
                  that appeal to you. A very distinct way of reading or writing
                  articles – NeoDrafts
                  thrives on creativity and encourages writers and bloggers to
                  connect and contribute their content. You have control over
                  the content you wish to write. At NeoDrafts, we don’t source
                  ads or pull up the incentives of advertising. We serve you,
                  the digital readers and writers.
                </p>
                <p>
                  Our aim is to change the landscape of storytelling and digital publishing.
                  We wish to grow by leaps and bounds with our dynamic team
                  through this open-source platform. We are different from the
                  crowd as our aim is to connect millions of people around the
                  globe on common grounds through different networking
                  communities and shared ideas. With NeoDrafts, you can decipher
                  and discover a new perspective and voice your opinions. Our
                  budding team works diligently to bring you fresh ideas and
                  lucrative deals from top-brands round the globe. So, register,
                  connect and start sharing your ideas and discover fantastic
                  content and enticing offers.
                </p>
              </div>
              <div className="sm_icons text-center mb-5">
                <i className="fa fa-facebook" aria-hidden="true"></i>
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Aboutus;
