import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import "../css/Trending.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../css/Home_Banner.css";
import "../css/Global.css";
import { BeatLoader } from "react-spinners";

const Trending_View = (props) => {
  const [education_latest, seteducation_latest] = useState([]);
  const [education_latest1, seteducation_latest1] = useState([]);
  const [trending, settrending] = useState([]);
  const [title_array, settitle_array] = useState([]);
  const [feautured_media, setfeautured_media] = useState("");
  const [loading, setLoading] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(async () => {
    const config = {
      url: props.data.url,
      method: "get",
    };

    await axios(config)
      .then(async (res) => {
        let edu_data = res.data;
        let i = 0;
        for (const item of edu_data) {
          const config1 = {
            url: "https://api.neodrafts.com/wp-json/base/views/" + item.id,
            method: "get",
          };

          const config2 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          await axios(config1).then((result) => {
            if (result.data) {
              edu_data[i++].most_view = result.data;
            } else {
              edu_data[i++].most_view = null;
            }
          });
        }

        seteducation_latest(edu_data);
      })

      .catch((error) => {
        console.log("error", error);
      });
  }, [props.url]);

  const arr_views = [];

  useEffect(async () => {
    let edu_data = education_latest.filter(
      (r) => r.featured_media !== 18907 && r.featured_media != 28329 && r.featured_media !== 0
    );
    // edu_data = res.data.filter((r) => r.featured_media != 18907);
    let i = 0;
    for (const item of edu_data) {
      const config1 = {
        url:
          "https://api.neodrafts.com/wp-json/wp/v2/media/" +
          item.featured_media,
        method: "get",
      };
      setLoading(true);

      const result = await axios(config1).then((result) => {
        if (result.data && result.data.source_url) {
          edu_data[i++].image_url = result.data.source_url;
        } else {
          edu_data[i++].image_url = null;
        }
      });
    }
    seteducation_latest1(edu_data);
  }, [education_latest]);

  useEffect(() => {
    const educ = education_latest1.sort((a, b) => {
      return b.most_view - a.most_view;
    });

    settrending(educ);
    setLoading(false);
  }, [education_latest1]);

  let education_data = trending.map((item, index) => {
    return (
      <>
        <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
          <Link
            to={{
              pathname: `/${item.slug}`,
              state: {
                item: item,
                you_may_like: trending,
                index: index,
              },
            }}
          >
            <div className="trending_col">
              <img src={item.image_url} />

              <h4>{item.title.rendered}</h4>
              <h5>
                Read More{" "}
                <span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </h5>
            </div>
          </Link>
        </div>
      </>
    );
  });

  return (
    <div>
      <Header />

      {loading ? (
        <div className="loader_div">
          <BeatLoader className="loader_class" size={30} color="#b62f71" />
        </div>
      ) : (
        <>
          <div className="education_banner_div trending_banner_image_div">
            <div className="education_banner_content">
              <h3>Trending Articles</h3>
            </div>
          </div>

          <div className="trending_component containerBox">
            <div className="row">{education_data}</div>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default Trending_View;
