import React, { Component } from 'react';
import '../css/Trending.css'
import editor1 from '../images/editor1.jpeg';
import editor2 from '../images/editor2.jpeg';
import editor3 from '../images/editor3.jpeg';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Editors extends Component {

  componentDidMount() {

    window.scrollTo(0, 0);

  }
  render() {
    return (
      <div>
        <div className="trending_component">
          <h2>Editors Pick</h2>
          <div className="row">

            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"earn-money-through-surveys-in-an-instant"}`,

                }}>

                <div className="trending_col">
                  <img src={editor1} alt="Image" />

                  <h4>Earn Money Through Surveys In An Instant</h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
              <Link
                to={{
                  pathname: `/${"join-the-highest-paying-survey-platforms-and-earn"}`,

                }}>

                <div className="trending_col">
                  <img src={editor3} alt="Image" />

                  <h4>Learn A-Z About unclaimed assets with a free guide
                  </h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>

            </div>
            <div className="col-lg-4 col-xs-12 col-sm-12 col-md-12">

              <Link
                to={{
                  pathname: `/${"find-your-unclaimed-assets-with-a-free-guide"}`,

                }}>

                <div className="trending_col">
                  <img src={editor2} alt="Image" />

                  <h4>    Join the Highest Paying Survey Platforms and Earn

                  </h4>
                  <h5>Read More <span><i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span></h5>
                </div>
              </Link>

            </div>

          </div>
          <Link to={{ pathname: `/editors-pick/${"posts"}` }}>
            <button>
              View All{" "}
              <span>
                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
              </span>
            </button>
          </Link>
          {/* <button>View All <span><i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
</span></button> */}
        </div>

      </div>
    );
  }
}

export default Editors;