import React, { Component } from "react";
import { Button, Form, FormControl, Nav, NavDropdown } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../images/neodrafts_logo.svg";
import Navbar from "react-bootstrap/Navbar";
import Home_Banner from "./Home_Banner";
import Trending from "./Trending";
import Editors from "./Editors";
import Latest from "./Latest";
import "../css/navbar.css";
import "../css/Global.css";
import "../css/Home.css";
import Side_Widget from "./Side_Widget";
import Footer from "./Footer";
import Header from "./Header";
import { TitleComponent } from "./TitleComponent";
//import { AiOutlineSearch } from "react-icons/ai";
import surveys from "../images/surveys.jpg";
import sample13 from "../images/sample13.png";
import WinFreebies from "../images/WinFreebies.jpg"
import Year_Posts from "./Year_Posts"
import FindingLove from "../images/FindingLove.jpg"


class Date_posts extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {

    return (
      <div>
        <TitleComponent title="Neodrafts - Be Inspired. Be Informed" />
        <Header />


        <div className="home_section">
          <div className="container-fluid containerBox">
            <div className="row">
              <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
                <div>
                  {/* <Trending url="https://api.neodrafts.com/wp-json/wp/v2/posts#orderbyasc"/> */}
                  {/* <Editors /> */}



                  <Year_Posts data={this.props.match.params.slug} />


                </div>
              </div>
              <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
                <Side_Widget>
                  <img src={surveys} alt="Image" />
                  <br />
                  <img src={WinFreebies} alt="Image" />
                </Side_Widget>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Date_posts;
