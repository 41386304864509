import axios from "axios";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "../css/Trending.css";
import sample10 from "../images/sample10.jpg";
import sample5 from "../images/sample5.jpg";
import sample6 from "../images/sample6.jpg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Side_Widget from "./Side_Widget";
import { TitleComponent } from "./TitleComponent";



const Search_result = (props) => {

  const [education_latest, seteducation_latest] = useState([]);
  const [title_array, settitle_array] = useState([]);
  const [feautured_media, setfeautured_media] = useState("")
  const [search_data, setSearch_data] = useState()
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  useEffect(() => {
    const data = props.match.url.replace("/search/", "");
    setSearch_data(data)
  }, [props])

  useEffect(async () => {
    const config = {
      url: "https://api.neodrafts.com/wp-json/wp/v2/posts?search=" + search_data,
      // url: "    https://api.neodrafts.com/wp-json/wp/v2/posts?filter[s]="+search_data,

      method: "get",
    };

    await axios(config)
      .then(async (res) => {
        let edu_data = res.data;

        let i = 0;
        for (const item of res.data) {
          const config1 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          await axios(config1).then((res) => {
            edu_data[i++].image_url = res.data.media_details.sizes.medium.source_url;
          });
        }

        seteducation_latest(edu_data)
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [search_data])

  let education_data = education_latest.map((item, index) => {
    return (
      <>
        <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
          <Link
            to={{
              pathname: `/${item.slug}`,

              state: {
                item: item,
                you_may_like: education_latest,
                index: index
              }



            }}
          >
            <div className="trending_col">
              <img src={item.image_url} />

              <h4>{item.title.rendered}</h4>
              <h5>
                Read More{" "}
                <span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </h5>
            </div>
          </Link>
        </div>
      </>
    );
  });


  return (
    <div>
      <TitleComponent title={search_data} />
      <Header />


      <div className="home_section">
        <div className="container-fluid containerBox">
          <div className="row">
            <div className="col-lg-9 col-sm-12 col-xs-12 col-md-12">
              <div>

                <div className="row">

                  <div className="search_res w-100 text-center">
                    <h2>{education_data.length != 0 ? `Total ${education_data.length} Results Shown Below` : "No Results Found"}</h2>

                  </div>



                  {education_data}



                </div>
                {/* <Trending url="https://api.neodrafts.com/wp-json/wp/v2/posts#orderbyasc"/>
                 <Editors />
                 <Latest url="https://api.neodrafts.com/wp-json/wp/v2/posts#orderbyasc"/> */}
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
              <Side_Widget />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default Search_result;