import logo from "./logo.svg";
import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Aboutus from "./components/Aboutus";
import Category_Main from "./components/Categories/Category_Main";
// import Dating from "./components/Categories/Dating";
import Finance from "./components/Categories/Finance";

import Wellness from "./components/Categories/Wellness";

import Insurance from "./components/Categories/Insurance";
import Gaming from "./components/Categories/Gaming";
import Category_View from "./components/Category_View";
import { useEffect } from "react";
import Category_Detailed from "./components/Categories/Category_Detailed";
import Trending_View from "./components/Trending_View";
import Category_MostViewed from "./components/Categories/Category_MostViewed";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Seach_result from "./components/Seach_result";
import PreviousComp from "./components/PreviousComp";
import Trending_Detailed from "./components/Trending_Detailed";
import MostViewed_Detailed from "./components/MostViewed_Detailed";
import axios from "axios";
import Editors_category from "./components/Editors_category";
import Date_posts from "./components/Date_posts";
import Year_Posts_All from "./components/Year_Posts_All";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact strict component={Home} />
          <Route
            path="/category/:slug"
            exact
            strict
            component={(props) => {
              const slug = props.match.params.slug;

              let data = {
                url:
                  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51#orderedbyasc",
                meta_title:
                  "Great Websites and Blogs for Students| Neo Drafts.",
                description: "Education blogs can give students a platform to find insights for their career. Read our blog posts on college search, education loans and many more.",
                image:
                  "https://cdn.neodrafts.com/uploads/2020/10/education_banner.png",
                sidebar_image: {
                  img1: "https://cdn.neodrafts.com/uploads/2021/04/Online-Courses.jpg",
                  img2: "https://cdn.neodrafts.com/uploads/2021/04/Right-Career.jpg"
                },
                links: {
                  // link1:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=29&aff_id=1076",
                  // link2:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=523&aff_id=1076"
                  link1: "",
                  link2: ""
                }

              };

              // if (slug == "gaming") {
              //   data = {
              //     url:
              //       "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc",
              //     meta_title:
              //       "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
              //     image:
              //       "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",

              //   };
              // }
              if (slug == "health") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc",
                  meta_title: " Best Health and Wellness Blogs | NeoDrafts",
                  description: "NeoDrafts is one of the best healthcare blogs in fitness, healthy foods and healthy lifestyles. We cover various different topics that you can relate to and follow.",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Health-Banner.jpg",
                  sidebar_image: {
                    img1: "https://cdn.neodrafts.com/uploads/2021/04/Win-Freebies.jpg",
                    img2: ""
                  },
                  links: {
                    link1: "",
                    link2: ""
                  }

                };
              }

              if (slug == "survey") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc",
                  meta_title:
                    " Best Survey Websites & Online Survey Platform | Neodrafts",
                  description: "Learn about the best online survey platforms and stand a chance to win many rewards with our survey articles.",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Surveys-Banner.jpg",
                  sidebar_image: {
                    img1: "https://cdn.neodrafts.com/uploads/2021/04/Paid-for-Surveys.jpg",
                    img2: "https://cdn.neodrafts.com/uploads/2021/04/Win-Freebies.jpg"
                  },
                  links: {
                    // link1:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=415&aff_id=1076",
                    // link2:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=515&aff_id=1076"
                    link1: "",
                    link2: ""
                  }

                };
              }

              // if (slug == "dating") {
              //   data = {
              //     url:
              //       "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc",
              //     meta_title:
              //       "Best Online Dating Blogs Websites | Neodrafts",
              //     description: "If you are looking for a friendship or random hookup, there are best free dating sites and dating blogs for every single out thereIf you are looking for friendship or casual dating, these are the best free dating sites and dating blogs for all and sundry..",
              //     image:
              //       "https://cdn.neodrafts.com/uploads/2020/10/Dating-Banner.jpg",
              //     sidebar_image: {
              //       img1: "https://cdn.neodrafts.com/uploads/2021/04/Finding-Love.jpg",
              //       img2: "https://cdn.neodrafts.com/uploads/2021/04/Meet-Singles.jpg"
              //     },
              //     links: {
              //       link1: "https://lookawoman.com/tt/07",
              //       link2: "https://www.lustylocals.com/aff.php"
              //     }
              //   };
              // }

              if (slug == "finance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc",
                  meta_title: "Best & Top Finance Blogs | Neodrafts",
                  description: "Keep yourself updated with new market trends and policies through our finance blogs. Neodrafts is a one-stop destination for all your finance related information requirements.",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Finance-Banner.jpg",
                  sidebar_image: {
                    img1: "https://cdn.neodrafts.com/uploads/2021/04/Loan.jpg",
                    img2: "https://cdn.neodrafts.com/uploads/2021/04/Protect-Your-House.jpg"
                  },
                  links: {
                    // link1:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=158&aff_id=1076",
                    // link2:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=38&aff_id=1076"
                    link1: "",
                    link2: ""
                  }
                };


              }

              if (slug == "wellness") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc",
                  meta_title: "Wellness Archives - NeoDrafts",
                  description: "Be Inspired. Be Informed.",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Wellness-Banner.jpg",
                  sidebar_image: {
                    img1: "",
                    img2: ""
                  },
                  links: {
                    link1: "",
                    link2: ""
                  }
                };
              }

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  description: "ignition casino, miami club, slots lv, slotocash, red dog Casino Gaming, planet 7 casino, red stag, bovegas, lucky, silver oak, intertops, royal ace",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                  sidebar_image: {
                    img1: "https://cdn.neodrafts.com/uploads/2021/04/Casino-Spins.jpg",
                    img2: "https://cdn.neodrafts.com/uploads/2021/04/Online-Casino.jpg"
                  },
                  links: {
                    // link1:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=153&aff_id=1076",
                    // link2:"https://adtrustmedia.go2cloud.org/aff_c?offer_id=153&aff_id=1076"
                    link1: "",
                    link2: ""
                  }
                };
              }

              if (slug == "news") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc",
                  meta_title:
                    "Trending News, Current Headlines, Viral Topics, Entertainment",
                  description: "Trending News, Current Headlines, Viral Topics, Entertainment, Sports, Hollywood, Breaking Celebrity News, Events, Fashions, Lifestyle, Health, Technology",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/NEWS-Banner-%E2%80%93-1.jpg",
                  sidebar_image: {
                    img1: "",
                    img2: ""
                  },
                  links: {
                    link1: "",
                    link2: ""
                  }

                };
              }

              if (slug == "technology") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc",
                  meta_title:
                    "Latest Technology News, Apple refurbished iPhone, Drone",
                  description: "Latest Technology News, apple refurbished iPhone, Samsung new phone, What is the Newest iPhone coming out Right Now, new Xbox release date, drones for sale",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Technology-Banner-%E2%80%93-2.jpg",
                  sidebar_image: {
                    img1: "",
                    img2: ""
                  },
                  links: {
                    link1: "",
                    link2: ""
                  }
                };
              }

              if (slug == "Insurance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc",
                  meta_title: "Top & Best Insurance Blogs | Neodrafts",
                  description: "Stay updated with all the latest Insurance information through NeoDrafts insurance blogs to take faster and smarter decisions.",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Insurance-Banner.jpg",
                  sidebar_image: {
                    img1: "https://cdn.neodrafts.com/uploads/2021/04/Instant-Loan.jpg",
                    img2: ""
                  },
                  links: {
                    // link1: "https://adtrustmedia.go2cloud.org/aff_c?offer_id=484&aff_id=1076",
                    // link2: "https://adtrustmedia.go2cloud.org/aff_c?offer_id=354&aff_id=1076"
                    link1: "",
                    link2: ""
                  }
                };
              }

              props.location.state = data;
              return <Category_Main data={data} />;
            }}
          />
          <Route path="/gaming" exact strict component={Gaming} />
          {/* <Route path="/dating" exact strict component={Dating} /> */}
          <Route path="/wellness" exact strict component={Wellness} />
          <Route path="/Insurance" exact strict component={Insurance} />
          <Route path="/finance" exact strict component={Finance} />
          <Route path="/contact" exact strict component={Contact} />
          <Route path="/aboutus" exact strict component={Aboutus} />

          <Route
            path="/category/:slug"
            exact
            strict
            component={(props) => {

              const slug = props.match.params.slug;

              let data = {
                url:
                  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51#orderedbyasc",
                meta_title:
                  "Great Websites and Blogs for Students| Neo Drafts.",
                image:
                  "https://cdn.neodrafts.com/uploads/2020/10/education_banner.png",
              };

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }
              if (slug == "health") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc",
                  meta_title: " Best Health and Wellness Blogs | NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Health-Banner.jpg",
                };
              }

              if (slug == "survey") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc",
                  meta_title:
                    " Best Survey Websites & Online Survey Platform | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Surveys-Banner.jpg",
                };
              }

              // if (slug == "dating") {
              //   data = {
              //     url:
              //       "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc",
              //     meta_title:
              //       "   Best Online Dating Blogs Websites | Neodrafts",
              //     image:
              //       "https://cdn.neodrafts.com/uploads/2020/10/Dating-Banner.jpg",
              //   };
              // }

              if (slug == "finance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc",
                  meta_title: "Best & Top Finance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Finance-Banner.jpg",
                };
              }

              if (slug == "wellness") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc",
                  meta_title: "Wellness Archives - NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Wellness-Banner.jpg",
                };
              }

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }

              if (slug == "news") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc",
                  meta_title:
                    "Trending News, Current Headlines, Viral Topics, Entertainment",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/NEWS-Banner-%E2%80%93-1.jpg",
                };
              }

              if (slug == "technology") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc",
                  meta_title:
                    "Latest Technology News, Apple refurbished iPhone, Drone",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Technology-Banner-%E2%80%93-2.jpg",
                };
              }

              if (slug == "Insurance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc",
                  meta_title: "Top & Best Insurance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Insurance-Banner.jpg",
                };
              }

              props.location.state = data;
              return <Category_View data={data} />;
            }}
          />

          <Route
            path="/category-view/:slug"
            exact
            strict
            component={(props) => {
              const slug = props.match.params.slug;

              let data = {
                url:
                  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51&per_page=100#orderedbyasc",
                meta_title:
                  "Great Websites and Blogs for Students| Neo Drafts.",
                image:
                  "https://cdn.neodrafts.com/uploads/2020/10/education_banner.png",
              };
              if (slug == "latest") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?per_page=100#orderbyasc",

                };
              }
              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6&per_page=100#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }
              if (slug == "health") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3&per_page=100#orderedbyasc",
                  meta_title: " Best Health and Wellness Blogs | NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Health-Banner.jpg",
                };
              }

              if (slug == "survey") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2&per_page=100#orderedbyasc",
                  meta_title:
                    " Best Survey Websites & Online Survey Platform | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Surveys-Banner.jpg",
                };
              }

              // if (slug == "dating") {
              //   data = {
              //     url:
              //       "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52&per_page=100#orderedbyasc",
              //     meta_title:
              //       "   Best Online Dating Blogs Websites | Neodrafts",
              //     image:
              //       "https://cdn.neodrafts.com/uploads/2020/10/Dating-Banner.jpg",
              //   };
              // }

              if (slug == "finance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49&per_page=100#orderedbyasc",
                  meta_title: "Best & Top Finance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Finance-Banner.jpg",
                };
              }

              if (slug == "wellness") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54&per_page=100#orderedbyasc",
                  meta_title: "Wellness Archives - NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Wellness-Banner.jpg",
                };
              }

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6&per_page=100#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }

              if (slug == "news") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565&per_page=100#orderedbyasc",
                  meta_title:
                    "Trending News, Current Headlines, Viral Topics, Entertainment",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/NEWS-Banner-%E2%80%93-1.jpg",
                };
              }

              if (slug == "technology") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50&per_page=100#orderedbyasc",
                  meta_title:
                    "Latest Technology News, Apple refurbished iPhone, Drone",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Technology-Banner-%E2%80%93-2.jpg",
                };
              }

              if (slug == "Insurance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7&per_page=100#orderedbyasc",
                  meta_title: "Top & Best Insurance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Insurance-Banner.jpg",
                };
              }

              props.location.state = data;
              return <Category_View data={data} />;
            }}
          />

          <Route
            path="/trending_view"
            exact
            strict
            component={(props) => {
              let data = {
                url:

                  "https://api.neodrafts.com/wp-json/wp/v2/posts?per_page=100#orderbyasc",

              };

              return <Trending_View data={data} />;
            }} />

          <Route
            path="/most-viewed-articles/:slug"
            exact
            strict
            component={(props) => {
              const slug = props.match.params.slug;

              let data = {
                url:
                  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51&per_page=100#orderedbyasc",
                meta_title:
                  "Great Websites and Blogs for Students| Neo Drafts.",
                image:
                  "https://cdn.neodrafts.com/uploads/2020/10/education_banner.png",
              };

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6&per_page=100#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }
              if (slug == "health") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3&per_page=100#orderedbyasc",
                  meta_title: " Best Health and Wellness Blogs | NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Health-Banner.jpg",
                };
              }

              if (slug == "survey") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2&per_page=100#orderedbyasc",
                  meta_title:
                    " Best Survey Websites & Online Survey Platform | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Surveys-Banner.jpg",
                };
              }

              // if (slug == "dating") {
              //   data = {
              //     url:
              //       "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52&per_page=100#orderedbyasc",
              //     meta_title:
              //       "   Best Online Dating Blogs Websites | Neodrafts",
              //     image:
              //       "https://cdn.neodrafts.com/uploads/2020/10/Dating-Banner.jpg",
              //   };
              // }

              if (slug == "finance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49&per_page=100#orderedbyasc",
                  meta_title: "Best & Top Finance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Finance-Banner.jpg",
                };
              }

              if (slug == "wellness") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54&per_page=100#orderedbyasc",
                  meta_title: "Wellness Archives - NeoDrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Wellness-Banner.jpg",
                };
              }

              if (slug == "gaming") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6&per_page=100#orderedbyasc",
                  meta_title:
                    "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg",
                };
              }

              if (slug == "news") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565&per_page=100#orderedbyasc",
                  meta_title:
                    "Trending News, Current Headlines, Viral Topics, Entertainment",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/NEWS-Banner-%E2%80%93-1.jpg",
                };
              }

              if (slug == "technology") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50&per_page=100#orderedbyasc",
                  meta_title:
                    "Latest Technology News, Apple refurbished iPhone, Drone",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Technology-Banner-%E2%80%93-2.jpg",
                };
              }

              if (slug == "Insurance") {
                data = {
                  url:
                    "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7&per_page=100#orderedbyasc",
                  meta_title: "Top & Best Insurance Blogs | Neodrafts",
                  image:
                    "https://cdn.neodrafts.com/uploads/2020/10/Insurance-Banner.jpg",
                };
              }

              props.location.state = data;
              return <Category_MostViewed data={data} />;
            }}
          />

          <Route path="/terms-of-use" exact strict component={Terms} />
          <Route path="/privacy" exact strict component={Privacy} />
          <Route path="/search/:slug" exact strict component={Seach_result} />

          <Route
            path="/category_detailed/previous"
            exact
            strict
            component={PreviousComp}
          />

          {/* <Route
            path="/category-detailed/:slug"
            exact
            strict
            component={(props) => {
              const slug = props.match.params.slug;
  
             let data={

              Dating:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52",
Survey:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2",
Gaming:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6",

Health:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3",

Finance:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49",

Insurance:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7",

Entertainment:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=25",

News:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565",

Technology:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50",

Wellness:
"https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54"

             }
              props.location.state = props;
              return <Category_Detailed  data={data} />;
            }}
          /> */}

          <Route
            path="/:slug"
            exact
            strict
            component={(props) => {
              return <Category_Detailed data={props.match.params.slug} />;
            }}


          />
          <Route
            path="/trending-detailed/:slug"
            exact
            strict
            component={Trending_Detailed}
          />
          <Route
            path="/editors-pick/:slug"
            exact
            strict
            component={Editors_category}
          />
          <Route
            path="/date/:slug"
            exact
            strict
            component={Date_posts}
          />
          <Route
            path="/most-viewed-detailed/:slug"
            exact
            strict
            component={MostViewed_Detailed}
          />
          <Route
            path="/all_posts/:slug"
            exact
            strict
            component={Year_Posts_All}
          />



          {/* <Route
            path="/category-detailed/:slug"
         
            render={(props)=>

              <Category_Detailed  {...props}/>
            }
            
          /> */}
        </Switch>
      </Router>
    );
  }
}

export default App;