import axios from "axios";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "../css/Trending.css";
import sample10 from "../images/sample10.jpg";
import sample5 from "../images/sample5.jpg";
import sample6 from "../images/sample6.jpg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../css/Home_Banner.css";

const Year_all_data = (props) => {
  const [education_latest, seteducation_latest] = useState([]);
  const [main_data, setmain_data] = useState([]);

  const [title_array, settitle_array] = useState([]);
  const [feautured_media, setfeautured_media] = useState("");
  const [cat_id, setcat_id] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
  })


  useEffect(() => {
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts#orderbyasc") {
      setcat_id("latest")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51#orderedbyasc") {
      setcat_id("education")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc") {
      setcat_id("gaming")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc") {
      setcat_id("health")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc") {
      setcat_id("survey")
    }
    // if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc") {
    //   setcat_id("dating")
    // }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc") {
      setcat_id("finance")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc") {
      setcat_id("wellness")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc") {
      setcat_id("news")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc") {
      setcat_id("technology")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc") {
      setcat_id("Insurance")
    }
  }, [props.url])

  useEffect(async () => {
    const config = {
      url: "https://www.neodrafts.com/wp-json/wp/v2/posts?per_page=100",
      method: "get",
    };

    await axios(config)
      .then(async (res) => {
        let edu_data = res.data.filter((r) => r.featured_media != 18907 && r.featured_media != 28329 && r.featured_media != 0 && r.date.includes(props.data));
        let i = 0;
        for (const item of edu_data) {
          const config1 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          const result = await axios(config1).then((result) => {
            if (result.data && result.data.source_url) {
              edu_data[i++].image_url = result.data.source_url;
            } else {
              edu_data[i++].image_url = null;
            }
          });
        }
        seteducation_latest(edu_data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.url]);

  useEffect(async () => {

    let main_data = education_latest;
    const new_data = []
    let i = 0;
    for (const item of main_data) {
      const config1 = {
        url:
          "https://api.neodrafts.com/wp-json/wp/v2/comments",

        method: "get",
      };

      const result = await axios(config1).then((result) => {
        const comments = []
        let i = 0;
        for (const item1 of result.data) {
          if (item1.post == item.id) {
            const data = {
              author_img: item1.author_avatar_urls[24],
              name: item1.author_name,
              description: item1.content.rendered
            }
            comments.push(data)

          }

        }

        item.comments = comments
        new_data.push(item)

      });
    }
    setmain_data(new_data)
  }, [education_latest])

  let education_data = main_data.map((item, index) => {
    return (
      <>
        <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">

          <Link
            to={{
              pathname: `/${item.slug}`,
              state: {
                item: item,
                you_may_like: main_data,
                index: index,

              }
            }}
          >
            <div className="trending_col">
              <img src={item.image_url} />

              <h4>{item.title.rendered}</h4>
              <h5>
                Read More{" "}
                <span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </h5>
            </div>
          </Link>
        </div>
      </>
    );

  });
  return (
    <div>
      <div className="trending_component">
        <h2>Latest Articles</h2>
        <div className="row">{education_data}</div>


        {/* <Link
              to={{
                pathname: `"/category-detailed/" ${item.slug}` ,
                state:{
                  item:item,
                  you_may_like:props.url,
                  index:index
                } */}

        {/* <div className="cat_view">
<Link to={{ pathname:`/all_posts/${props.data}`}}>
          <button>
            View All{" "}
            <span>
              <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </span>
          </button>
        </Link>
</div> */}


      </div>
    </div>
  );
};

export default Year_all_data;
