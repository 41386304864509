import React, { useState, useEffect } from "react";
import { Button, Container, Form, FormControl, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

// import "bootstrap/dist/css/bootstrap.min.css";
import "../css/navbar.css";
import "../css/Global.css";
import "../css/Home.css";
import Logo from "../images/neodrafts_logo.svg";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";
import categoryApi from "./Categories/categoryApi.json";
const Header = () => {
  const [category_url, setcategory_url] = useState("");
  const [value, setValue] = useState("");
  const [key_down_val, setKey_down_val] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleNavCollapse = () => setExpanded(false);

  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value)

  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setValue(event.target.value)
      setKey_down_val(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  if (key_down_val) {
    return <Redirect to={{
      pathname: "/search/" + value

    }} />
  }
  return (
    <div>
      <div className="Header">
        <div className="container-fluid containerBox">
          <Navbar collapseOnSelect expand="lg" className="neo_nav" expanded={expanded}
            onToggle={() => setExpanded(!expanded)}>
            <Container>
              <Navbar.Brand
                as={Link}
                to="/"
                onClick={handleNavCollapse}
                className="logo_div"
              >
                <img src={Logo} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="nav_right pl-5">
                  <Nav.Link
                    as={Link}
                    to={{
                      pathname: "/category/" + "education",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51#orderedbyasc",
                        meta_title: "Great Websites and Blogs for Students| Neo Drafts.",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/education_banner.png"
                      },
                    }}
                    onClick={handleNavCollapse}
                    className={window.location != "https://app.neodrafts.com/category/education" ? 'x' : 'y'}
                  >
                    Education
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={{
                      pathname: "/category/" + "health",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc",
                        meta_title: " Best Health and Wellness Blogs | NeoDrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Health-Banner.jpg"
                      },
                    }}
                    onClick={handleNavCollapse}
                    className={window.location != "https://app.neodrafts.com/category/health" ? 'x' : 'y'}
                  >
                    Health
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={{
                      pathname: "/category/" + "survey",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc",
                        meta_title: " Best Survey Websites & Online Survey Platform | Neodrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Surveys-Banner.jpg"
                      },
                    }}
                    onClick={handleNavCollapse}
                    className={window.location != "https://app.neodrafts.com/category/survey" ? 'x' : 'y'}
                  >
                    Survey
                  </Nav.Link>
                  <NavDropdown title="More" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "gaming",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc",
                        meta_title: "Red Dog Casino, ignition, slots lv, slotocash, stag, lucky Gaming",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Gaming-Banner.jpg"
                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/gaming" ? 'x' : 'y'}
                    >
                      Gaming
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "dating",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc",
                        meta_title: "   Best Online Dating Blogs Websites | Neodrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Dating-Banner.jpg"


                      },
                    }}
                      onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/dating" ? 'x' : 'y'}
                    >
                      Dating
                    </NavDropdown.Item> */}
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "finance",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc",
                        meta_title: "Best & Top Finance Blogs | Neodrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Finance-Banner.jpg"

                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/finance" ? 'x' : 'y'}
                    >
                      Finance
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "wellness",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc",
                        meta_title: "Wellness Archives - NeoDrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Wellness-Banner.jpg"

                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/wellness" ? 'x' : 'y'}
                    >
                      Wellness
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "news",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc",
                        meta_title: "Trending News, Current Headlines, Viral Topics, Entertainment",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/NEWS-Banner-%E2%80%93-1.jpg"

                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/news" ? 'x' : 'y'}
                    >
                      News
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "technology",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc",
                        meta_title: "Latest Technology News, Apple refurbished iPhone, Drone",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Technology-Banner-%E2%80%93-2.jpg"
                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/technology" ? 'x' : 'y'}
                    >
                      Technology
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={{
                      pathname: "/category/" + "Insurance",
                      state: {
                        url:
                          "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc",
                        meta_title: "Top & Best Insurance Blogs | Neodrafts",
                        image: "https://cdn.neodrafts.com/uploads/2020/10/Insurance-Banner.jpg"
                      },
                    }} onClick={handleNavCollapse}
                      className={window.location != "https://app.neodrafts.com/category/Insurance" ? 'x' : 'y'}
                    >
                      Insurance
                    </NavDropdown.Item>
                  </NavDropdown>
                  <a href="https://api.neodrafts.com/wp-login.php?redirect_to=https%3A%2F%2Fneodrafts.com%2Fwp-admin%2F&reauth=1">
                    <Button className="signin_btn">Sign In</Button>
                  </a>
                </Nav>
                <Form inline className="position-relative">
                  <FormControl
                    type="text"
                    className="input_header"
                    placeholder="Search"
                    value={value}
                    onChange={handleChange}
                    onKeyPress={handleEnter}
                  />
                  <div className="search_icon position-absolute">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </div>
                  {/* <Button variant="outline-success">Search</Button> */}
                </Form>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>



    </div>
  );
};

export default Header;
