import axios from "axios";
import React, { useState, useEffect } from "react";
import "../css/Trending.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../css/Home_Banner.css";
import { BeatLoader } from "react-spinners"

const MostViewed = (props) => {
  const [education_latest, seteducation_latest] = useState([]);
  const [education_latest1, seteducation_latest1] = useState([]);
  const [cat_id, setcat_id] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=51#orderedbyasc") {
      setcat_id("education")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc") {
      setcat_id("gaming")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc") {
      setcat_id("health")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc") {
      setcat_id("survey")
    }
    // if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc") {
    //   setcat_id("dating")
    // }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc") {
      setcat_id("finance")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc") {
      setcat_id("wellness")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc") {
      setcat_id("news")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc") {
      setcat_id("technology")
    }
    if (props.url == "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc") {
      setcat_id("Insurance")
    }
  }, [props.url])


  useEffect(async () => {
    const config = {
      url: props.url,
      method: "get",
    };
    setLoading(true)
    await axios(config)
      .then(async (res) => {
        let edu_data = res.data;
        let i = 0;
        for (const item of edu_data) {
          const config1 = {
            url: "https://api.neodrafts.com/wp-json/base/views/" + item.id,
            method: "get",
          };

          const config2 = {
            url:
              "https://api.neodrafts.com/wp-json/wp/v2/media/" +
              item.featured_media,
            method: "get",
          };

          await axios(config1).then((result) => {
            if (result.data) {
              edu_data[i++].most_view = result.data;
            } else {
              edu_data[i++].most_view = null;
            }
          });
        }
        seteducation_latest(edu_data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.url]);

  const arr_views = [];

  useEffect(async () => {
    let edu_data = education_latest.filter((r) => r.featured_media != 18907 && r.featured_media != 28329 && r.featured_media != 0);
    // edu_data = res.data.filter((r) => r.featured_media != 18907);
    let i = 0;
    for (const item of edu_data) {
      const config1 = {
        url: "https://api.neodrafts.com/wp-json/wp/v2/media/" + item.featured_media,
        method: "get",
      };

      const result = await axios(config1).then((result) => {
        if (result.data && result.data.source_url) {
          edu_data[i++].image_url = result.data.source_url;
          setLoading(false)
        } else {
          edu_data[i++].image_url = null;
        }
      });
    }

    seteducation_latest1(edu_data);
  }, [education_latest]);

  const educ = education_latest1.sort((a, b) => {
    return b.most_view - a.most_view;
  });

  let education_data = educ.map((item, index) => {
    if (index < 6) {
      return (
        <>
          <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
            <Link
              to={{
                pathname: `/${item.slug}`,
                state: {
                  item: item,
                  you_may_like: educ,
                  index: index
                }
              }}
            >
              <div className="trending_col">
                <img src={item.image_url} />
                <h4>{item.title.rendered}</h4>
                <h5>
                  Read More{" "}
                  <span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </h5>
              </div>
            </Link>
          </div>
        </>
      );
    }

  });

  return (
    <div>
      <div className="trending_component">
        <h2>Most Viewed Articles</h2>
        <div className="row">
          {loading ? <div className="loader_div"><BeatLoader className="loader_class" size={30} color="#b62f71" /></div> : education_data
          }
        </div>

        <div className="cat_view">
          <Link to={{ pathname: `/most-viewed-articles/${cat_id}`, data: props.url }}>
            <button className="mt-5">
              View All{" "}
              <span>
                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MostViewed;
