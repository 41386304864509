import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const categoryMappings = {
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderbyasc": "latest",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=6#orderedbyasc": "gaming",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=3#orderedbyasc": "health",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=2#orderedbyasc": "survey",
  // "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=52#orderedbyasc": "dating",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=49#orderedbyasc": "finance",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=54#orderedbyasc": "wellness",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=565#orderedbyasc": "news",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=50#orderedbyasc": "technology",
  "https://api.neodrafts.com/wp-json/wp/v2/posts?categories=7#orderedbyasc": "Insurance"
};

const Latest = ({ url }) => {
  const [educationLatest, setEducationLatest] = useState([]);
  const [educationLatest1, setEducationLatest1] = useState([]);
  const [latest, setLatest] = useState([]);
  // const [loading, setLoading] = useState(true);

  const catId = categoryMappings[url];

  /** 
      This useEffect hook is used to fetch data from the api and 
      it updates the state variable educationLatest with the fetched data. 
  */
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch('https://api.neodrafts.com/posts-retrive-api.php?page=1&per_page=6&category=')
          .then(res => res.json());
        const edu_data = response;

        // let i = 0;
        // for (const item of edu_data.posts) {
        //   const result = await fetch(`https://api.neodrafts.com/wp-json/base/views/${item.id}`);
        //   const resultData = await result.json();

        //   edu_data[i++].most_view = resultData || null;
        // }

        setEducationLatest(edu_data.posts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url]);


  /**
    This useEffect filters out unwanted media and fetches data for the remaining media items. 
    It updates the state variable educationLatest1 with the fetched media data.
   */
  useEffect(() => {
    const fetchMediaData = async () => {
      let edu_data = educationLatest.filter(
        (r) => r.featured_media != 18907 && r.featured_media != 28329 && r.featured_media != 0
      );
      let i = 0;
      for (const item of edu_data) {
        const config1 = {
          url:
            "https://api.neodrafts.com/wp-json/wp/v2/media/" +
            item.featured_media,
          method: "get",
        };

        const result = await axios(config1).then((result) => {
          if (result.data && result.data.source_url) {
            edu_data[i++].image_url = result.data.source_url;
            // setLoading(false);
          } else {
            edu_data[i++].image_url = null;
          }
        });
      }
      setEducationLatest1(edu_data);
    };

    fetchMediaData();
  }, [educationLatest]);

  /**
    The third useEffect hook sorts the educationLatest1 data based on the 
    most views and updates the latest state variable.
   */
  useEffect(() => {
    const sortAndSetLatest = () => {
      const educ = educationLatest1.sort((a, b) => b.most_view - a.most_view);
      setLatest(educ);
    };

    sortAndSetLatest();
  }, [educationLatest1]);

  // Rendering the latest data and displays information for the top 3 latest articles.
  const educationData = latest
    .slice(0, 6)
    .map((item, index) => (
      <div key={index} className="col-lg-4 col-xs-12 col-sm-12 col-md-12">
        <Link
          to={{
            pathname: `/${item.slug}`,

            state: {
              item: item,
              you_may_like: latest,
              index: index,
            },
          }}
        >
          <div className="trending_col">
            <img src={item.image} />

            <h4>{item.title}</h4>
            <h5>
              Read More{" "}
              <span>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
        </Link>
      </div>
    ));

  return (
    <div>
      <div className="trending_component">
        <h2>Latest Articles</h2>
        <div className="row">
          {educationData.length > 0 ? (
            educationData
          ) : (
            <div className="loader_div_01">
              <BeatLoader className="loader_class" size={30} color="#b62f71" />
            </div>
          )}
        </div>
        <div className="cat_view">
          <Link to={{ pathname: '/category-view/latest' }}>
            <button className="mt-5">
              View All{" "}
              <span>
                <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

// const LoadingSpinner = () => (
//   <div className="loader_div_01">
//     <BeatLoader className="loader_class" size={30} color="#b62f71" />
//   </div>
// );

export default Latest;
